<script>
import { MuiModal } from '@emobg/motion-ui/v1';
import get from 'lodash/get';
import { mapActions, mapMutations, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CancelButton, DeleteButton } from '@/components';
export default {
  components: {
    DeleteButton,
    MuiModal,
    CancelButton,
  },
  props: {
    circleUuid: {
      type: String,
      required: true,
    },
    refreshTable: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.circles, {
      isError: state => get(state, 'circle.STATUS.ERROR'),
      isLoading: state => get(state, 'circle.STATUS.LOADING'),
    }),
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.circles, [
      'deleteCircle',
    ]),
    ...mapMutations(DOMAINS_MODEL.app.messages.notifications, [
      'notify',
    ]),
    async onDelete() {
      await this.deleteCircle({ circleUuid: this.circleUuid });
      if (!this.isError) {
        this.notify({
          message: 'Circle successfully <span class="emobg-font-weight-semibold">deleted</span>',
          textAction: '',
        });
        this.refreshTable();
        this.$emit('close-modal');
      }
    },
  },
};
</script>
<template>
  <MuiModal
    :size="SIZES.medium"
    :header="{
      title: 'Delete this circle?',
      class: 'pl-3',
      isClosable: true,
    }"
    is-open
    data-test-id="delete-modal"
    @modal-closed="$emit('close-modal')"
  >
    <div slot="body">
      <div class="emobg-color-ink emobg-font-line-height-large">
        <div class="emobg-font-weight-bold">
          All managers of this circle will be unassigned.
        </div>
        <div class="mt-3 emobg-font-weight-semibold">
          Employees in this circle will still be able to:
        </div>
        <ul
          class="pl-4"
          style="list-style: disc;"
        >
          <li>Manage (shorten, cancel..) existing bookings related to this circle (except extending the booking)</li>
        </ul>
        <div class="mt-3 emobg-font-weight-semibold">
          Employees in this circle will no longer be able to:
        </div>
        <ul
          class="pl-4"
          style="list-style: disc;"
        >
          <li>Extend existing bookings related to this circle</li>
          <li>Create new bookings related to this circle</li>
        </ul>
        <div class="mt-3">
          Once deleted you cannot revert the changes.
        </div>
      </div>
    </div>
    <div
      slot="footer"
      class="p-3 d-flex justify-content-end"
    >
      <CancelButton @click="$emit('close-modal')" />

      <DeleteButton
        data-test-id="delete-fleet-segment-confirm"
        :loading="isLoading"
        @click="onDelete"
      />
    </div>
  </MuiModal>
</template>
