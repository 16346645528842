import { RECORD_TYPES } from '@emobg/motion-ui';
import remove from 'lodash/remove';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import crm from '@domains/CRM/router/CRMRouterMap';
import PermissionLink from '@/components/PermissionLink/PermissionLink';
import CirclesFleetSegmentsLinks from './components/CirclesFleetSegmentsLinks';

export const contentCells = (companyUuid, showFleetSegments = true) => {
  const cells = [
    {
      title: 'Name',
      displayPriority: 1,
      minWidth: 140,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => ({
        classLink: 'emobg-link-primary emobg-body-2',
        to: {
          name: crm.companies.detail.circles.edit,
          params: {
            companyUuid,
            circleUuid: result.uuid,
          },
        },
        linkPermissions: [CRM_PERMISSIONS.viewCrmUsers],
        text: result.alias,
      }),
    },
    {
      title: 'Employees',
      attributeName: 'total_users',
      displayPriority: 1,
      minWidth: 120,
    },
    {
      title: 'Circle managers',
      attributeName: 'managers',
      displayPriority: 1,
      minWidth: 120,
      transformValue: (managers = []) => managers.length,
    },
    {
      title: 'Fleet segments',
      attributeName: 'fleet_segments',
      displayPriority: 2,
      minWidth: 120,
      transformValue: (fleetSegments = []) => fleetSegments.length,
    },
    {
      title: 'Fleet segments names',
      minWidth: 200,
      type: RECORD_TYPES.component,
      component: CirclesFleetSegmentsLinks,
      props: ({ fleet_segments: fleetSegments }) => ({
        companyUuid,
        fleetSegments,
      }),
    },
  ];
  if (!showFleetSegments) {
    remove(cells, { title: 'Fleet segments' });
    remove(cells, { title: 'Fleet segments names' });
  }
  return cells;
};
