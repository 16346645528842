<script>
export default {
  name: 'CompanyCirclesView',
};
</script>

<template>
  <div
    class="CompanyCirclesView"
    data-test-id="company_circles-view"
  >
    <RouterView />
  </div>
</template>
