<script>
import { MuiAlgoliaList } from '@emobg/motion-ui';
import { DELAY, navigationErrorHandler } from '@emobg/web-utils';
import { mapState } from 'vuex';
import get from 'lodash/get';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { getNoResultsLabel, refreshAlgoliaStore } from '@/utils';
import { contentCells } from './contentCells';
import DeleteCircleModal from './DeleteCircleModal/DeleteCircleModal';
import crmRouterMap from '../../../router/CRMRouterMap';
import { COMPANY_SCOPES } from '../../store/CompanyModule';

export default {
  name: 'CompanyCirclesListView',
  components: {
    DeleteCircleModal,
    MuiAlgoliaList,
  },
  data() {
    return {
      showDeleteModal: false,
      deleteCircleUuid: '',
      companyId: undefined,
      contentCells: [],
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => state[COMPANY_SCOPES.company].data,
      hasFleetSegments: state => get(state, 'company.data.allowFleetSegments'),
    }),
  },
  async created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.crmRouterMap = crmRouterMap;
    this.navigationErrorHandler = navigationErrorHandler;
    this.algoliaItemActions = [
      {
        label: 'Edit circle',
        method: ({ uuid }) => this.$router.push({
          name: crmRouterMap.companies.detail.circles.edit,
          params: { circleUuid: uuid },
        }).catch(navigationErrorHandler),
      },
      {
        label: 'Delete circle',
        class: 'emobg-color-danger',
        method: ({ uuid }) => {
          this.deleteCircleUuid = uuid;
          this.showDeleteModal = true;
        },
      },
    ];
    this.contentCells = contentCells(this.company.uuid, this.hasFleetSegments);
    this.companyId = this.company.id;
    // This is temporary meanwhile BE speed up algolia indexation
    // TODO: https://europcarmobility.atlassian.net/browse/SBSETUP-1558
    this.refreshTable();
  },
  methods: {
    getNoResultsLabel,
    refreshTable() {
      refreshAlgoliaStore(this.$refs.circlesTable, DELAY.extraLong);
    },
  },
};
</script>
<template>
  <div
    class="CompanyCirclesListView"
    data-test-id="company_circles_list-view"
  >
    <div class="d-flex justify-content-between align-items-center mb-2">
      <div class="d-flex align-items-center">
        <h1>
          Circles
        </h1>
        <ui-tooltip
          tooltip="Groups of employees with access to selected fleet segments"
          :placement="PLACEMENTS.right"
          class="d-inline-block ml-1"
        >
          <ui-icon
            :icon="ICONS.infoFull"
            class="emobg-color-ink-light emobg-color-ink-hover"
          />
        </ui-tooltip>
      </div>
      <ui-button
        data-test-id="create-button"
        @clickbutton="$router.push({ name: crmRouterMap.companies.detail.circles.create }).catch(navigationErrorHandler)"
      >
        Create new circle
      </ui-button>
    </div>
    <MuiAlgoliaList
      v-if="companyId"
      ref="circlesTable"
      :filters="`company_id:${companyId}`"
      :index="ALGOLIA_INDEXES.circles"
      :table-config="contentCells"
      :item-actions="algoliaItemActions"
      :empty-message="getNoResultsLabel('circles')"
      data-test-id="list"
    />
    <DeleteCircleModal
      v-if="showDeleteModal"
      :circle-uuid="deleteCircleUuid"
      :refresh-table="refreshTable"
      @close-modal="showDeleteModal = false"
    />
  </div>
</template>
