var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MuiModal",
    {
      attrs: {
        size: _vm.SIZES.medium,
        header: {
          title: "Delete this circle?",
          class: "pl-3",
          isClosable: true,
        },
        "is-open": "",
        "data-test-id": "delete-modal",
      },
      on: {
        "modal-closed": function ($event) {
          return _vm.$emit("close-modal")
        },
      },
    },
    [
      _c("div", { attrs: { slot: "body" }, slot: "body" }, [
        _c(
          "div",
          { staticClass: "emobg-color-ink emobg-font-line-height-large" },
          [
            _c("div", { staticClass: "emobg-font-weight-bold" }, [
              _vm._v(" All managers of this circle will be unassigned. "),
            ]),
            _c("div", { staticClass: "mt-3 emobg-font-weight-semibold" }, [
              _vm._v(" Employees in this circle will still be able to: "),
            ]),
            _c(
              "ul",
              { staticClass: "pl-4", staticStyle: { "list-style": "disc" } },
              [
                _c("li", [
                  _vm._v(
                    "Manage (shorten, cancel..) existing bookings related to this circle (except extending the booking)"
                  ),
                ]),
              ]
            ),
            _c("div", { staticClass: "mt-3 emobg-font-weight-semibold" }, [
              _vm._v(" Employees in this circle will no longer be able to: "),
            ]),
            _c(
              "ul",
              { staticClass: "pl-4", staticStyle: { "list-style": "disc" } },
              [
                _c("li", [
                  _vm._v("Extend existing bookings related to this circle"),
                ]),
                _c("li", [
                  _vm._v("Create new bookings related to this circle"),
                ]),
              ]
            ),
            _c("div", { staticClass: "mt-3" }, [
              _vm._v(" Once deleted you cannot revert the changes. "),
            ]),
          ]
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "p-3 d-flex justify-content-end",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("CancelButton", {
            on: {
              click: function ($event) {
                return _vm.$emit("close-modal")
              },
            },
          }),
          _c("DeleteButton", {
            attrs: {
              "data-test-id": "delete-fleet-segment-confirm",
              loading: _vm.isLoading,
            },
            on: { click: _vm.onDelete },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }