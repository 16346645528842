<script>
import isEmpty from 'lodash/isEmpty';
import crm from '@domains/CRM/router/CRMRouterMap';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import { PermissionLink } from '@/components';

export default {
  name: 'CirclesFleetSegmentsLinks',
  components: {
    PermissionLink,
  },
  props: {
    /**
     * [{
     *   name: string,
     *   uuid: string,
     * }]
     */
    fleetSegments: {
      type: Array,
      default: () => [],
    },
    companyUuid: {
      type: String,
      required: true,
    },
  },
  created() {
    this.crm = crm;
    this.CRM_PERMISSIONS = CRM_PERMISSIONS;
  },
  methods: {
    isEmpty,
  },
};
</script>

<template>
  <div class="CirclesFleetSegmentsLink">
    <template v-if="isEmpty(fleetSegments)">
      {{ FALLBACK_MESSAGE.dash }}
    </template>
    <PermissionLink
      v-for="fleetSegment in fleetSegments"
      v-else
      :key="fleetSegment.uuid"
      :to="{
        name: crm.companies.detail.fleetSegments.edit,
        params: {
          companyUuid,
          fleetSegmentUuid: fleetSegment.uuid,
        }
      }"
      :text="fleetSegment.name"
      :link-permissions="[CRM_PERMISSIONS.viewFleetSegments, CRM_PERMISSIONS.manageFleetSegments]"
      class="emobg-font-weight-semibold"
    />
  </div>
</template>
