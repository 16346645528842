var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "CirclesFleetSegmentsLink" },
    [
      _vm.isEmpty(_vm.fleetSegments)
        ? [_vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " ")]
        : _vm._l(_vm.fleetSegments, function (fleetSegment) {
            return _c("PermissionLink", {
              key: fleetSegment.uuid,
              staticClass: "emobg-font-weight-semibold",
              attrs: {
                to: {
                  name: _vm.crm.companies.detail.fleetSegments.edit,
                  params: {
                    companyUuid: _vm.companyUuid,
                    fleetSegmentUuid: fleetSegment.uuid,
                  },
                },
                text: fleetSegment.name,
                "link-permissions": [
                  _vm.CRM_PERMISSIONS.viewFleetSegments,
                  _vm.CRM_PERMISSIONS.manageFleetSegments,
                ],
              },
            })
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }