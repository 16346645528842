import { COLORS } from '@emobg/vue-base';
import { FALLBACK_MESSAGE, sentenceCase } from '@emobg/web-utils';
import { RECORD_TYPES } from '@emobg/motion-ui';
import get from 'lodash/get';
import snakeCase from 'lodash/snakeCase';
import map from 'lodash/map';
import join from 'lodash/join';
import crm from '@domains/CRM/router/CRMRouterMap';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import { PermissionLink } from '@/components';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';
import { getWorkingHoursTypeLabel, renderSchedule } from '../../FleetSegments/config/contentCells';
import BookingRulesTemplate from '../../FleetSegments/components/BookingRulesTemplate';
import { USER_STATUS_COLORS } from '../../../../Users/constants/userStatus';

export const employeesContentCells = selectedEmployees => [
  {
    title: '',
    type: RECORD_TYPES.checkbox,
    displayPriority: 1,
    model: selectedEmployees,
    columnRatio: 0.01,
    minWidth: 52,
    key: 'uuid',
    value: ({ uuid }) => ({ uuid }),
  },
  {
    title: 'Name',
    attributeName: 'user_uuid',
    minWidth: 120,
    displayPriority: 1,
    columnRatio: 1,
    type: RECORD_TYPES.component,
    component: PermissionLink,
    props: result => ({
      classLink: 'emobg-link-primary emobg-body-2',
      to: {
        name: crm.users.detail.account,
        params: {
          userUuid: result.user_uuid,
        },
      },
      linkPermissions: [CRM_PERMISSIONS.viewCrmUsers],
      text: `${result.user_first_name} ${result.user_last_name}`,
    }),
  },
  {
    attributeName: 'email',
    title: 'Account email',
    displayPriority: 1,
    minWidth: 150,
  },
  {
    attributeName: 'dedicated_fleet_status',
    title: 'Business status',
    displayPriority: 1,
    minWidth: 150,
    type: RECORD_TYPES.component,
    component: BadgeComponent,
    props: result => ({
      text: sentenceCase(result.open_fleet_status),
      color: USER_STATUS_COLORS[snakeCase(result.open_fleet_status)] || COLORS.danger,
    }),
  },
  {
    attributeName: 'open_fleet_status',
    title: 'Personal status',
    displayPriority: 1,
    minWidth: 150,
    type: RECORD_TYPES.component,
    component: BadgeComponent,
    props: result => ({
      text: sentenceCase(result.open_fleet_status),
      color: USER_STATUS_COLORS[snakeCase(result.open_fleet_status)] || COLORS.danger,
    }),
  },
  {
    attributeName: 'user_roles',
    title: 'User roles',
    displayPriority: 1,
    minWidth: 150,
    transformValue: roles => join(map(roles, 'display_name'), ', '),
  },
];

export const fleetSegmentsContentCells = (context, selectedFleetSegments) => [
  {
    title: '',
    type: RECORD_TYPES.checkbox,
    displayPriority: 1,
    model: selectedFleetSegments,
    columnRatio: 0.01,
    minWidth: 52,
    key: 'uuid',
    value: ({ uuid }) => ({ uuid }),
  },
  {
    title: 'Name',
    displayPriority: 1,
    columnRatio: 1,
    type: RECORD_TYPES.component,
    minWidth: 120,
    component: PermissionLink,
    props: result => ({
      classLink: 'emobg-link-primary emobg-body-2',
      to: {
        name: crm.companies.detail.fleetSegments.edit,
        params: {
          companyUuid: get(result, 'company.uuid'),
          fleetSegmentUuid: result.uuid,
        },
      },
      linkPermissions: [CRM_PERMISSIONS.viewCrmCompanies],
      text: result.name,
    }),
  },
  {
    title: 'Circles',
    attributeName: 'circles',
    displayPriority: 1,
    minWidth: 120,
    transformValue: (circles = []) => circles.length,
  },
  {
    title: 'Vehicles',
    attributeName: 'vehicles',
    displayPriority: 1,
    minWidth: 120,
    transformValue: (vehicles = []) => vehicles.length,
  },
  {
    attributeName: 'working_hours',
    minWidth: 75,
    displayPriority: 1,
    columnRatio: 1,
    transformResult: getWorkingHoursTypeLabel,
    title: 'Dedicated fleet',
  },
  {
    attributeName: 'custom_booking_rules',
    minWidth: 75,
    displayPriority: 1,
    columnRatio: 1,
    transformValue: value => (value ? 'Custom values' : 'Operator\'s default values'),
    title: 'Booking rules',
  },
  {
    title: 'Dedicated fleet schedule',
    minWidth: 180,
    attributeName: 'working_hours',
    type: RECORD_TYPES.template,
    isCollapseHidden: true,
    columnRatio: 2,
    template: result => renderSchedule(result.working_hours, FALLBACK_MESSAGE.dash),
  },
  {
    title: 'Description',
    attributeName: 'internal_remarks',
    minWidth: 120,
  },
  {
    attributeName: 'booking_rules',
    type: RECORD_TYPES.component,
    component: BookingRulesTemplate,
    props: result => ({ bookingRules: result.booking_rules, hasCustomRules: result.custom_booking_rules }),
    isCollapseHidden: true,
    title: null,
  },
  {
    title: 'Circle names',
    attributeName: 'circles',
    minWidth: 120,
  },
];
