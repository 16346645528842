var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "CompanyCirclesListView",
      attrs: { "data-test-id": "company_circles_list-view" },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-between align-items-center mb-2",
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-items-center" },
            [
              _c("h1", [_vm._v(" Circles ")]),
              _c(
                "ui-tooltip",
                {
                  staticClass: "d-inline-block ml-1",
                  attrs: {
                    tooltip:
                      "Groups of employees with access to selected fleet segments",
                    placement: _vm.PLACEMENTS.right,
                  },
                },
                [
                  _c("ui-icon", {
                    staticClass: "emobg-color-ink-light emobg-color-ink-hover",
                    attrs: { icon: _vm.ICONS.infoFull },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "ui-button",
            {
              attrs: { "data-test-id": "create-button" },
              on: {
                clickbutton: function ($event) {
                  _vm.$router
                    .push({
                      name: _vm.crmRouterMap.companies.detail.circles.create,
                    })
                    .catch(_vm.navigationErrorHandler)
                },
              },
            },
            [_vm._v(" Create new circle ")]
          ),
        ],
        1
      ),
      _vm.companyId
        ? _c("MuiAlgoliaList", {
            ref: "circlesTable",
            attrs: {
              filters: `company_id:${_vm.companyId}`,
              index: _vm.ALGOLIA_INDEXES.circles,
              "table-config": _vm.contentCells,
              "item-actions": _vm.algoliaItemActions,
              "empty-message": _vm.getNoResultsLabel("circles"),
              "data-test-id": "list",
            },
          })
        : _vm._e(),
      _vm.showDeleteModal
        ? _c("DeleteCircleModal", {
            attrs: {
              "circle-uuid": _vm.deleteCircleUuid,
              "refresh-table": _vm.refreshTable,
            },
            on: {
              "close-modal": function ($event) {
                _vm.showDeleteModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }