var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "CreateEditCircles" }, [
    _c(
      "div",
      { staticClass: "d-flex align-items-center" },
      [
        _c("h3", { staticClass: "emobg-font-weight-semibold" }, [
          _vm._v(
            " " +
              _vm._s(_vm.circleUuid ? "Edit circle" : "Create new circle") +
              " "
          ),
        ]),
        _c(
          "ui-tooltip",
          {
            staticClass: "d-inline-block ml-1",
            attrs: {
              tooltip:
                "Groups of employees with access to selected fleet segments",
              placement: _vm.PLACEMENTS.right,
            },
          },
          [
            _c("ui-icon", {
              staticClass: "emobg-color-ink-light emobg-color-ink-hover",
              attrs: { icon: _vm.ICONS.infoFull },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "emobg-background-color-white emobg-border-top-1 emobg-border-left-1 emobg-border-right-1 emobg-border-color-ground-light mt-2 position-relative",
      },
      [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "px-3 pt-3 pb-4" },
          [
            _c("div", { staticClass: "emobg-font-weight-bold mb-1" }, [
              _vm._v(" Company name "),
            ]),
            _c("div", [_vm._v(" " + _vm._s(_vm.companyName) + " ")]),
            _c("ui-text-input", {
              staticClass: "mt-4",
              attrs: {
                value: _vm.form.alias,
                name: "circleName",
                label: "Circle name*",
              },
              on: { changevalue: (event) => (_vm.form.alias = event.detail) },
            }),
          ],
          1
        ),
        _c(
          "FormCollapseSection",
          {
            staticClass: "mb-3",
            attrs: {
              title: "Employees*",
              "open-text": "Hide",
              "close-text": "Show",
            },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "d-flex align-items-center justify-content-between p-3 emobg-background-color-white",
              },
              [
                _c("div", { staticClass: "emobg-color-ink-light" }, [
                  _c("div", { staticClass: "mb-1" }, [
                    _vm._v(" Select at least one employee to create a circle "),
                  ]),
                  _c("div", [
                    _vm._v(" Only employees with business status "),
                    _c("span", { staticClass: "emobg-font-weight-bold" }, [
                      _vm._v("activated"),
                    ]),
                    _vm._v(" will show as drivers in Webapp "),
                  ]),
                ]),
                _c("ui-button-segments", {
                  key: _vm.selectedEmployees.length,
                  attrs: {
                    value: _vm.showSelectedEmployees,
                    name: "showSelectedEmployees",
                  },
                  domProps: { options: _vm.employeesTableOptions },
                  on: {
                    clickbuttonsegment: ({ detail }) =>
                      (_vm.showSelectedEmployees = detail),
                  },
                }),
              ],
              1
            ),
            _vm.employeesContentCells
              ? _c("MuiAlgoliaList", {
                  ref: "vehiclesList",
                  staticStyle: { border: "0" },
                  attrs: {
                    index: _vm.ALGOLIA_INDEXES.employees,
                    "table-config": _vm.employeesContentCells,
                    "query-parameters": { hitsPerPage: 10 },
                    filters: _vm.employeesAlgoliaFilter,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "FormCollapseSection",
          {
            staticClass: "mb-3",
            attrs: {
              title: "Booking managers",
              "open-text": "Hide",
              "close-text": "Show",
            },
          },
          [
            _c(
              "div",
              { staticClass: "p-3" },
              [
                _c("div", { staticClass: "emobg-color-ink-light my-3" }, [
                  _vm._v(
                    " Members of this circle who can create and manage bookings on behalf of others through web-app "
                  ),
                ]),
                _c("MuiAlgoliaSelect", {
                  staticClass: "w-100",
                  attrs: {
                    title: (employee) => employee.email,
                    index: _vm.ALGOLIA_INDEXES.employees,
                    filters: _vm.managersFilter,
                    name: "managers",
                    "path-value": "uuid",
                    placeholder: "Select",
                    label: "Booking managers",
                    multiple: "",
                  },
                  model: {
                    value: _vm.selectedManagers,
                    callback: function ($$v) {
                      _vm.selectedManagers = $$v
                    },
                    expression: "selectedManagers",
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _vm.hasFleetSegments
          ? _c(
              "FormCollapseSection",
              {
                staticClass: "mb-3",
                attrs: {
                  title: "Fleet Segments",
                  "open-text": "Hide",
                  "close-text": "Show",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-between p-3 emobg-background-color-white",
                  },
                  [
                    _c("div", { staticClass: "emobg-color-ink-light" }, [
                      _c("div", { staticClass: "mb-1" }, [
                        _vm._v(
                          " Dedicated fleet segments available for members of this circle "
                        ),
                      ]),
                      _c("div", [
                        _vm._v(
                          " (Note: only Dedicated when scheduled and Always dedicated fleet segments are available) "
                        ),
                      ]),
                    ]),
                    _c("ui-button-segments", {
                      key: _vm.selectedFleetSegments.length,
                      attrs: {
                        value: _vm.showSelectedEmployees,
                        name: "showSelectedFleetSegments",
                        "data-test-id": "filters",
                      },
                      domProps: { options: _vm.fleetSegmentsTableOptions },
                      on: {
                        clickbuttonsegment: ({ detail }) =>
                          (_vm.showSelectedFleetSegments = detail),
                      },
                    }),
                  ],
                  1
                ),
                _vm.fleetSegmentsContentCells
                  ? _c("MuiAlgoliaList", {
                      ref: "fleetSegmentsList",
                      staticStyle: { border: "0" },
                      attrs: {
                        index: _vm.ALGOLIA_INDEXES.fleetSegments,
                        "table-config": _vm.fleetSegmentsContentCells,
                        filters: _vm.fleetSegmentsAlgoliaFilter,
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass:
              "emobg-border-top-1 emobg-border-color-ground-light position-sticky z-index-100 emobg-background-color-white p-3 d-flex justify-content-end align-items-center",
            staticStyle: { bottom: "0" },
          },
          [
            _c("CancelButton", {
              staticClass: "mr-2",
              attrs: { "data-test-id": "cancel-button" },
              on: { click: _vm.goToCircles },
            }),
            _c(
              "ui-button",
              {
                attrs: {
                  disabled: !_vm.isValid || (_vm.circleUuid && !_vm.hasChanges),
                  loading: _vm.isLoading,
                  "data-test-id": "save-button",
                },
                on: { clickbutton: _vm.saveCircle },
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.circleUuid ? "Save" : "Create circle") + " "
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "emobg-background-color-ground-lightest p-3" },
      [
        _c("h3", { staticClass: "emobg-font-weight-light" }, [
          _vm._v(" Details* "),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }