<script>
import { MuiAlgoliaSelect } from '@emobg/motion-ui/v1';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import { FALLBACK_MESSAGE, navigationErrorHandler } from '@emobg/web-utils';
import { mapActions, mapState } from 'vuex';
import join from 'lodash/join';
import map from 'lodash/map';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import { USER_STATUS } from '@domains/CRM/Users/constants/userStatus';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { CancelButton } from '@/components';

import FormCollapseSection from '../../FleetSegments/components/FormCollapseSection';
import { employeesContentCells, fleetSegmentsContentCells } from './contentCells';
import crmRouterMap from '../../../../router/CRMRouterMap';

export default {
  name: 'CreateEditCircles',
  components: {
    FormCollapseSection,
    MuiAlgoliaList,
    MuiAlgoliaSelect,
    CancelButton,
  },
  data() {
    return {
      employeesContentCells: undefined,
      fleetSegmentsContentCells: undefined,
      circleUuid: undefined,
      showSelectedEmployees: false,
      showSelectedFleetSegments: false,
      selectedEmployees: [],
      selectedManagers: [],
      selectedFleetSegments: [],
      form: {
        alias: '',
        internalRemarks: FALLBACK_MESSAGE.dash,
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      companyUuid: state => get(state, 'company.data.uuid'),
      companyName: state => get(state, 'company.data.commercialName'),
      hasFleetSegments: state => get(state, 'company.data.allowFleetSegments'),
    }),
    ...mapState(DOMAINS_MODEL.crm.circles, {
      currentCircle: state => get(state, 'data'),
      isError: state => get(state, 'STATUS.ERROR'),
      isLoading: state => get(state, 'STATUS.LOADING'),
    }),
    employeesAlgoliaFilter() {
      const companyFilter = `company_uuid:${this.companyUuid} AND NOT employee_status:${USER_STATUS.anonymized}`;
      if (!isEmpty(this.selectedEmployees) && this.showSelectedEmployees) {
        const employeesFilters = map(this.selectedEmployees, ({ uuid }) => `uuid:${uuid}`);
        return `${companyFilter} AND ${join(employeesFilters, ' OR ')}`;
      }
      return companyFilter;
    },
    fleetSegmentsAlgoliaFilter() {
      const companyFilter = `company.uuid:${this.companyUuid}`;
      if (!isEmpty(this.selectedFleetSegments) && this.showSelectedFleetSegments) {
        const fleetSegmentsFilter = map(this.selectedFleetSegments, ({ uuid }) => `uuid:${uuid}`);
        return `${companyFilter} AND ${join(fleetSegmentsFilter, ' OR ')}`;
      }
      return companyFilter;
    },
    employeesTableOptions() {
      return [
        { label: 'All', value: false },
        { label: `Selected (${this.selectedEmployees.length})`, value: true },
      ];
    },
    fleetSegmentsTableOptions() {
      return [
        { label: 'All', value: false },
        { label: `Selected (${this.selectedFleetSegments.length})`, value: true },
      ];
    },
    managersFilter() {
      const employeesFilter = join(map(this.selectedEmployees, ({ uuid }) => `uuid:${uuid}`), ' OR ');
      if (isEmpty(this.selectedEmployees)) {
        return `company_uuid:${this.companyUuid} AND uuid:no`;
      }
      return `company_uuid:${this.companyUuid} AND (${employeesFilter})`;
    },
    isValid() {
      return this.form.alias && !isEmpty(this.selectedEmployees);
    },
    hasChanges() {
      const circleUsers = map(get(this.currentCircle, 'users'), 'uuid');
      const circleManagers = map(get(this.currentCircle, 'managers'), 'uuid');
      const circleFleetSegments = map(get(this.currentCircle, 'fleetSegments'), 'uuid');
      const hasChanges = this.form.alias !== get(this.currentCircle, 'alias')
        || !isEqual(sortBy(map(this.selectedEmployees, 'uuid')), sortBy(circleUsers))
        || !isEqual(sortBy(this.selectedManagers), sortBy(circleManagers))
        || !isEqual(sortBy(map(this.selectedFleetSegments, 'uuid')), sortBy(circleFleetSegments));
      return hasChanges;
    },
  },
  async created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.circleUuid = get(this.$route, 'params.circleUuid');
    if (this.circleUuid) {
      await this.getCircle({ circleUuid: this.circleUuid });
      this.setCircle();
    }
    this.employeesContentCells = employeesContentCells(this.selectedEmployees);
    this.fleetSegmentsContentCells = fleetSegmentsContentCells(this, this.selectedFleetSegments);
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.circles, [
      'getCircle',
      'putCircle',
      'postCircle',
      'putAssignEmployees',
      'putAssignManagers',
      'postAssignFleetSegments',
    ]),
    setCircle() {
      this.form.alias = get(this.currentCircle, 'alias');
      this.selectedFleetSegments = map(get(this.currentCircle, 'fleetSegments'), ({ uuid }) => ({ uuid }));
      this.selectedEmployees = map(get(this.currentCircle, 'users'), ({ uuid }) => ({ uuid }));
      this.selectedManagers = map(get(this.currentCircle, 'managers'), ({ uuid }) => uuid);
    },
    async saveCircle() {
      if (this.circleUuid) {
        await this.putCircle({ circleUuid: this.circleUuid, data: { ...this.form, companyUuid: this.companyUuid } });
        await this.updateRelations(get(this.currentCircle, 'uuid'));
        if (!this.isError) {
          this.$notify({
            message: 'Circle successfully <span class="emobg-font-weight-semibold">edited</span>',
            textAction: '',
          });
          this.goToCircles();
        }
      } else {
        await this.postCircle({ data: { ...this.form, companyUuid: this.companyUuid } });
        await this.updateRelations(get(this.currentCircle, 'uuid'));
        if (!this.isError) {
          this.$notify({
            message: 'Circle sucessfully <span class="emobg-font-weight-semibold">created</span>',
            textAction: '',
          });
          this.goToCircles();
        }
      }
    },
    updateRelations(circleUuid) {
      return Promise.all([
        this.putAssignEmployees({ circleUuid, data: { userUuids: map(this.selectedEmployees, 'uuid') } }),
        this.putAssignManagers({ circleUuid, data: { userUuids: this.selectedManagers } }),
        this.postAssignFleetSegments({ circleUuid, data: { fleetSegmentUuids: map(this.selectedFleetSegments, ({ uuid }) => uuid) } }),
      ]);
    },
    goToCircles() {
      this.$router.push({ name: crmRouterMap.companies.detail.circles.index }).catch(navigationErrorHandler);
    },
  },
};
</script>
<template>
  <div class="CreateEditCircles">
    <div class="d-flex align-items-center">
      <h3 class="emobg-font-weight-semibold">
        {{ circleUuid ? 'Edit circle' : 'Create new circle' }}
      </h3>
      <ui-tooltip
        tooltip="Groups of employees with access to selected fleet segments"
        :placement="PLACEMENTS.right"
        class="d-inline-block ml-1"
      >
        <ui-icon
          :icon="ICONS.infoFull"
          class="emobg-color-ink-light emobg-color-ink-hover"
        />
      </ui-tooltip>
    </div>
    <div
      class="
        emobg-background-color-white emobg-border-top-1
        emobg-border-left-1 emobg-border-right-1 emobg-border-color-ground-light
        mt-2 position-relative
      "
    >
      <div class="emobg-background-color-ground-lightest p-3">
        <h3 class="emobg-font-weight-light">
          Details*
        </h3>
      </div>
      <div class="px-3 pt-3 pb-4">
        <div class="emobg-font-weight-bold mb-1">
          Company name
        </div>
        <div>
          {{ companyName }}
        </div>
        <ui-text-input
          :value="form.alias"
          name="circleName"
          class="mt-4"
          label="Circle name*"
          @changevalue="event => form.alias = event.detail"
        />
      </div>
      <FormCollapseSection
        title="Employees*"
        open-text="Hide"
        close-text="Show"
        class="mb-3"
      >
        <div class="d-flex align-items-center justify-content-between p-3 emobg-background-color-white">
          <div class="emobg-color-ink-light">
            <div class="mb-1">
              Select at least one employee to create a circle
            </div>
            <div>
              Only employees with business status <span class="emobg-font-weight-bold">activated</span> will show as drivers in Webapp
            </div>
          </div>
          <ui-button-segments
            :key="selectedEmployees.length"
            :value="showSelectedEmployees"
            :options.prop="employeesTableOptions"
            name="showSelectedEmployees"
            @clickbuttonsegment="({ detail }) => showSelectedEmployees = detail"
          />
        </div>
        <MuiAlgoliaList
          v-if="employeesContentCells"
          ref="vehiclesList"
          :index="ALGOLIA_INDEXES.employees"
          :table-config="employeesContentCells"
          :query-parameters="{ hitsPerPage: 10 }"
          :filters="employeesAlgoliaFilter"
          style="border: 0;"
        />
      </FormCollapseSection>
      <FormCollapseSection
        title="Booking managers"
        open-text="Hide"
        close-text="Show"
        class="mb-3"
      >
        <div class="p-3">
          <div class="emobg-color-ink-light my-3">
            Members of this circle who can create and manage bookings on behalf of others through web-app
          </div>
          <MuiAlgoliaSelect
            v-model="selectedManagers"
            :title="employee => employee.email"
            :index="ALGOLIA_INDEXES.employees"
            :filters="managersFilter"
            name="managers"
            path-value="uuid"
            placeholder="Select"
            label="Booking managers"
            class="w-100"
            multiple
          />
        </div>
      </FormCollapseSection>
      <FormCollapseSection
        v-if="hasFleetSegments"
        title="Fleet Segments"
        open-text="Hide"
        close-text="Show"
        class="mb-3"
      >
        <div class="d-flex align-items-center justify-content-between p-3 emobg-background-color-white">
          <div class="emobg-color-ink-light">
            <div class="mb-1">
              Dedicated fleet segments available for members of this circle
            </div>
            <div>
              (Note: only Dedicated when scheduled and Always dedicated fleet segments are available)
            </div>
          </div>
          <ui-button-segments
            :key="selectedFleetSegments.length"
            :value="showSelectedEmployees"
            :options.prop="fleetSegmentsTableOptions"
            name="showSelectedFleetSegments"
            data-test-id="filters"
            @clickbuttonsegment="({ detail }) => showSelectedFleetSegments = detail"
          />
        </div>
        <MuiAlgoliaList
          v-if="fleetSegmentsContentCells"
          ref="fleetSegmentsList"
          :index="ALGOLIA_INDEXES.fleetSegments"
          :table-config="fleetSegmentsContentCells"
          :filters="fleetSegmentsAlgoliaFilter"
          style="border: 0;"
        />
      </FormCollapseSection>
      <div
        class="
          emobg-border-top-1 emobg-border-color-ground-light position-sticky z-index-100
          emobg-background-color-white p-3 d-flex justify-content-end align-items-center
        "
        style="bottom: 0;"
      >
        <CancelButton
          class="mr-2"
          data-test-id="cancel-button"
          @click="goToCircles"
        />
        <ui-button
          :disabled="!isValid || (circleUuid && !hasChanges)"
          :loading="isLoading"
          data-test-id="save-button"
          @clickbutton="saveCircle"
        >
          {{ circleUuid ? 'Save' : 'Create circle' }}
        </ui-button>
      </div>
    </div>
  </div>
</template>
